import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_OpenHAB_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Using OpenHab3 in Docker with an WQHD Camera",
  "path": "/Frequently_Asked_Question/OpenHab3_Docker_IpCamera/",
  "dateChanged": "2022-12-13",
  "author": "Mike Polinowski",
  "excerpt": "I am using OpenHab in Docker and tried to add my camera using the ipcamera binding. But I am unable to access the video stream or snapshot.",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Using OpenHab3 in Docker with an WQHD Camera' dateChanged='2022-12-13' author='Mike Polinowski' tag='INSTAR IP Camera' description='I am using OpenHab in Docker and tried to add my camera using the ipcamera binding. But I am unable to access the video stream or snapshot.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/OpenHab3_Docker_IpCamera/' locationFR='/fr/Frequently_Asked_Question/OpenHab3_Docker_IpCamera/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    
    <h1 {...{
      "id": "using-openhab3-in-docker-with-an-wqhd-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#using-openhab3-in-docker-with-an-wqhd-camera",
        "aria-label": "using openhab3 in docker with an wqhd camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using OpenHab3 in Docker with an WQHD Camera`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I am using OpenHab in Docker and tried to add my camera using the `}<a parentName="p" {...{
        "href": "https://openhab-next.netlify.app/addons/bindings/ipcamera/"
      }}>{`ipcamera binding`}</a>{`. But I am unable to access the video stream or snapshot. Even though the binding shows that my camera is connected.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: When you are using Docker to run OpenHab we recommend that you set the `}<strong parentName="p">{`Network Mode`}</strong>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`host`}</code>{`. This way your container is directly connected to your local network and will be able to communicate with your camera. For example on a Linux system you can first create the folders you want to use to persists the data OpenHab will generate:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` /opt/openhab`}</code></pre></div>
    <p><em parentName="p">{`Make sure that the docker container is allowed to write into this folder!`}</em></p>
    <p>{`Now you can start the container with - `}<strong parentName="p">{`note`}</strong>{` the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`--net=host`}</code>{` flag:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    --name openhab `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    --net`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`host `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    --privileged `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    --rm `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    -v /etc/localtime:/etc/localtime:ro `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    -v /opt/openhab/openhab_addons:/openhab/addons `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    -v /opt/openhab/openhab_conf:/openhab/conf `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    -v /opt/openhab/openhab_userdata:/openhab/userdata `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    -d `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    openhab/openhab`}</code></pre></div>
    <p>{`When you log in to OpenHab you will see that it is using the "wrong" network interface. The streaming port will be bound to the internal Docker container IP - which is not accessible from your local network:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ee5b47911fa698fbb02ffef9a17910ca/8d68c/OpenHab3_IPCamera_Docker_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAA8klEQVQY04XLvW6DMBwEcB4gbQ22wR/4bxxsgwnEiZShsGVpl1aZ8hBVVfX9tyqEgaXKTzed7hJrbd/3Mcamad3W7Nq2tjfuxlvr2jZ0XbcUC++cD84lxpj7VAPkRcEYAw0AIKUUQnDOGWNFwfhCrMtkH4++CQoqqAyh9GmzGYbhdZyGfq91Na/lPaVSCrTWlZQSIZQilJzi7hQ7C9xpkVPyglAIYRynw+E478qyVOsoBUpBNkvMx0/9+cvfvuX7F1F1ip4xIZTmlFKMyT/By9mfr/58LccLTBcqIEUoy3A6yx5JOlf1fmuBW+A5wY8fq/Mf3OEwKCfCxTAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ee5b47911fa698fbb02ffef9a17910ca/e4706/OpenHab3_IPCamera_Docker_01.avif 230w", "/en/static/ee5b47911fa698fbb02ffef9a17910ca/d1af7/OpenHab3_IPCamera_Docker_01.avif 460w", "/en/static/ee5b47911fa698fbb02ffef9a17910ca/7f308/OpenHab3_IPCamera_Docker_01.avif 920w", "/en/static/ee5b47911fa698fbb02ffef9a17910ca/e83e9/OpenHab3_IPCamera_Docker_01.avif 1234w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ee5b47911fa698fbb02ffef9a17910ca/a0b58/OpenHab3_IPCamera_Docker_01.webp 230w", "/en/static/ee5b47911fa698fbb02ffef9a17910ca/bc10c/OpenHab3_IPCamera_Docker_01.webp 460w", "/en/static/ee5b47911fa698fbb02ffef9a17910ca/966d8/OpenHab3_IPCamera_Docker_01.webp 920w", "/en/static/ee5b47911fa698fbb02ffef9a17910ca/5dcdf/OpenHab3_IPCamera_Docker_01.webp 1234w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ee5b47911fa698fbb02ffef9a17910ca/81c8e/OpenHab3_IPCamera_Docker_01.png 230w", "/en/static/ee5b47911fa698fbb02ffef9a17910ca/08a84/OpenHab3_IPCamera_Docker_01.png 460w", "/en/static/ee5b47911fa698fbb02ffef9a17910ca/c0255/OpenHab3_IPCamera_Docker_01.png 920w", "/en/static/ee5b47911fa698fbb02ffef9a17910ca/8d68c/OpenHab3_IPCamera_Docker_01.png 1234w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ee5b47911fa698fbb02ffef9a17910ca/c0255/OpenHab3_IPCamera_Docker_01.png",
              "alt": "Using OpenHab3 in Docker with an WQHD Camera",
              "title": "Using OpenHab3 in Docker with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open up the `}<strong parentName="p">{`Network`}</strong>{` settings:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5b9990d1fd05872c484748279b38c163/cd78c/OpenHab3_IPCamera_Docker_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABcklEQVQoz4WQy67TMBCGs6chiUOdxI6vM0ntNG44cQnqKeK2YsEGxAPAe8ADHJ4aJZWKxIZP/2Zm9Ekzk0gpEbHreqVNcP38cjLWmv+hjUFrEmtMGMcQAlgLgDFGznlVVfU/VFVT1/esjbpOnB+MhYZx3oqSlGmako3iL3lKSFqWO0J2RbErimdFkW2DZPrxdPz+m335xb/+3JuheL7TcHh8vGqtm4Y1TUOr+r0232L8oM25fLHs6WVPOSE5IYkLs5/O2p2Mn2jV5HnWKniYIyICoFKK8faThc9+eCfkm4ZdGf/IuLrJ3h0Gf7BGgzWU0jzPuO4e4isEtBaU0i1vTX/wp4kpXQt5CylLssreH49Hu34LNjkXUocxcM4ZWz9B97RDjHMEwLYVUkgpZHmTnVvlbclNzjJl++X1BbFD7IyxQsiu62M8j2OwFkQrxF2e57gsi3POWqC0Wm+G4XJ9ewqng/MAIIQERO8Ht5YohLzLfwD1IETkuXGeLAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5b9990d1fd05872c484748279b38c163/e4706/OpenHab3_IPCamera_Docker_02.avif 230w", "/en/static/5b9990d1fd05872c484748279b38c163/d1af7/OpenHab3_IPCamera_Docker_02.avif 460w", "/en/static/5b9990d1fd05872c484748279b38c163/7f308/OpenHab3_IPCamera_Docker_02.avif 920w", "/en/static/5b9990d1fd05872c484748279b38c163/2adae/OpenHab3_IPCamera_Docker_02.avif 1236w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5b9990d1fd05872c484748279b38c163/a0b58/OpenHab3_IPCamera_Docker_02.webp 230w", "/en/static/5b9990d1fd05872c484748279b38c163/bc10c/OpenHab3_IPCamera_Docker_02.webp 460w", "/en/static/5b9990d1fd05872c484748279b38c163/966d8/OpenHab3_IPCamera_Docker_02.webp 920w", "/en/static/5b9990d1fd05872c484748279b38c163/5b1dd/OpenHab3_IPCamera_Docker_02.webp 1236w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5b9990d1fd05872c484748279b38c163/81c8e/OpenHab3_IPCamera_Docker_02.png 230w", "/en/static/5b9990d1fd05872c484748279b38c163/08a84/OpenHab3_IPCamera_Docker_02.png 460w", "/en/static/5b9990d1fd05872c484748279b38c163/c0255/OpenHab3_IPCamera_Docker_02.png 920w", "/en/static/5b9990d1fd05872c484748279b38c163/cd78c/OpenHab3_IPCamera_Docker_02.png 1236w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5b9990d1fd05872c484748279b38c163/c0255/OpenHab3_IPCamera_Docker_02.png",
              "alt": "Using OpenHab3 in Docker with an WQHD Camera",
              "title": "Using OpenHab3 in Docker with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And select the network interface of your local machine:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a56f7ad7914c449d130cc03e8b546ce6/cd78c/OpenHab3_IPCamera_Docker_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABXUlEQVQoz3WRTY/UMAxA5w/ApC3TOHbsdJyk39OiSoOAEycuaK/7S+ACfx62M4IVC0/v4MtTLOdw1ris69tt68eJwAZCcA7AAoDbUdWcMxEBACKKSPAk6BrCg6CNgaNQQFuYJ4goxkTkARyAs9aeTvVtRqTmrJ2eq7I8FsVh+/Rl+viZpvd+/nBybI5HFrle3/X9wCwhNM+VEJjFeymrqizLw/D4vX38gQ/f6OHrm2Y0r19l4WmaUsrO4UsR0TlX7hzapGOXYuDUSF2fjDHMPPSDiLj/4Jmr+8vjOE6zxhRTttYaY0RkWVbV6D0zh38p97jv+2maVTXGaK09GtM1sm3bMIxEnlleCuir29rzfFnXteu6W2yMQaKc26gxhOCevu0PFgAdJKb7wS6Xy7Isbdv+jr33KeUY4765/ytmdNdW77HuxJ26rveYVZWInHPWwnPr2iL53PVFUfyKfwLEakYaRC3rmQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a56f7ad7914c449d130cc03e8b546ce6/e4706/OpenHab3_IPCamera_Docker_03.avif 230w", "/en/static/a56f7ad7914c449d130cc03e8b546ce6/d1af7/OpenHab3_IPCamera_Docker_03.avif 460w", "/en/static/a56f7ad7914c449d130cc03e8b546ce6/7f308/OpenHab3_IPCamera_Docker_03.avif 920w", "/en/static/a56f7ad7914c449d130cc03e8b546ce6/2adae/OpenHab3_IPCamera_Docker_03.avif 1236w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a56f7ad7914c449d130cc03e8b546ce6/a0b58/OpenHab3_IPCamera_Docker_03.webp 230w", "/en/static/a56f7ad7914c449d130cc03e8b546ce6/bc10c/OpenHab3_IPCamera_Docker_03.webp 460w", "/en/static/a56f7ad7914c449d130cc03e8b546ce6/966d8/OpenHab3_IPCamera_Docker_03.webp 920w", "/en/static/a56f7ad7914c449d130cc03e8b546ce6/5b1dd/OpenHab3_IPCamera_Docker_03.webp 1236w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a56f7ad7914c449d130cc03e8b546ce6/81c8e/OpenHab3_IPCamera_Docker_03.png 230w", "/en/static/a56f7ad7914c449d130cc03e8b546ce6/08a84/OpenHab3_IPCamera_Docker_03.png 460w", "/en/static/a56f7ad7914c449d130cc03e8b546ce6/c0255/OpenHab3_IPCamera_Docker_03.png 920w", "/en/static/a56f7ad7914c449d130cc03e8b546ce6/cd78c/OpenHab3_IPCamera_Docker_03.png 1236w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a56f7ad7914c449d130cc03e8b546ce6/c0255/OpenHab3_IPCamera_Docker_03.png",
              "alt": "Using OpenHab3 in Docker with an WQHD Camera",
              "title": "Using OpenHab3 in Docker with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To restart OpenHab you need to remove the container:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rm`}</span>{` -f openhab`}</code></pre></div>
    <p>{`And use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker run`}</code>{` command from above again to restart it. Now you can see that the camera binding is using the correct network interface:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5c71999a95547055ce74933c7aa5e30f/85053/OpenHab3_IPCamera_Docker_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABeklEQVQoz2WRe4rbQAzGfYHg8djjedgazzPG4yfEgfqf3ZYWmhuUsLCF0gvkLLlwiW12A/0hhPjEJyEUOedCCH3fO+ctlFbrSn0ipbxcLtfrVf2H1yrSWjvn6rqulCoBlNYfba01Y+x2u93vdyGEfmptRPN8bkIrCgC5r6xWtsI7fzrNy7I0TbOJm76bT0MzBe+k8FVBc5JmOxjjNE0xxnEcHw4HhBBeyZ6I7I938/29+PpWvvzKOCQoppQByBDapgld1w/jNM/naZzCg1YpxRjfBkX+8tf8/FN8+12+vmVcJijmXEBZAsiiKJ31XdeP49i23UMDKYSglO7m1uvglAVmJSdZhhCqlFauJjnNc0pInmVki3xV6JrxelHUDeM4Tf5YG+sIIUmCuCjBHCnjOSH0iYeZUMoZFWzfPAzjMAzee2MMIQQhpCq1LF+MMZxzIQT/QHBKqBn98dwkcYJTHLkVa+1mThIkQJu601obY6VUAPI5pKwA5PaIfyelTEhkiu+WAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5c71999a95547055ce74933c7aa5e30f/e4706/OpenHab3_IPCamera_Docker_04.avif 230w", "/en/static/5c71999a95547055ce74933c7aa5e30f/d1af7/OpenHab3_IPCamera_Docker_04.avif 460w", "/en/static/5c71999a95547055ce74933c7aa5e30f/7f308/OpenHab3_IPCamera_Docker_04.avif 920w", "/en/static/5c71999a95547055ce74933c7aa5e30f/3c69e/OpenHab3_IPCamera_Docker_04.avif 1225w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5c71999a95547055ce74933c7aa5e30f/a0b58/OpenHab3_IPCamera_Docker_04.webp 230w", "/en/static/5c71999a95547055ce74933c7aa5e30f/bc10c/OpenHab3_IPCamera_Docker_04.webp 460w", "/en/static/5c71999a95547055ce74933c7aa5e30f/966d8/OpenHab3_IPCamera_Docker_04.webp 920w", "/en/static/5c71999a95547055ce74933c7aa5e30f/1d10f/OpenHab3_IPCamera_Docker_04.webp 1225w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5c71999a95547055ce74933c7aa5e30f/81c8e/OpenHab3_IPCamera_Docker_04.png 230w", "/en/static/5c71999a95547055ce74933c7aa5e30f/08a84/OpenHab3_IPCamera_Docker_04.png 460w", "/en/static/5c71999a95547055ce74933c7aa5e30f/c0255/OpenHab3_IPCamera_Docker_04.png 920w", "/en/static/5c71999a95547055ce74933c7aa5e30f/85053/OpenHab3_IPCamera_Docker_04.png 1225w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5c71999a95547055ce74933c7aa5e30f/c0255/OpenHab3_IPCamera_Docker_04.png",
              "alt": "Using OpenHab3 in Docker with an WQHD Camera",
              "title": "Using OpenHab3 in Docker with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      